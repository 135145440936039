<template>
  <Page id="e404">
    <div style="margin-top: -80px" />
    <section
      id="e404-main"
      class="section-indent true-center"
      aria-labelledby="aria-e404"
    >
      <h1 class="title">E404</h1>
      <div id="e404-content" class="true-center">
        <p id="aria-e404">It looks like this page doesn't exist.</p>
        <p>
          Click on the button below to be taken back and turn that frown upside
          down.
        </p>
      </div>
      <Button value="Take Me Back" @click="navHome" />
    </section>
  </Page>
</template>

<script>
import Button from "@/components/global/Button.vue";
import Page from "@/components/core/Page.vue";

export default {
  components: {
    Button,
    Page,
  },
  methods: {
    navHome() {
      this.$router.push("/");
    },
  },
};
</script>

<style scoped>
#e404-main {
  flex-direction: column;
  gap: 40px;
}

#e404-content {
  flex-direction: column;
  gap: 0;
}
</style>
